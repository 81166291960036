/* eslint-disable no-param-reassign */
import { ref, toRefs } from 'vue';
import {
  API_ROUTE_BRANDING_TOOLS,
} from '@/router/routes';
import {
  BrandingTools,
  GenericJson,
} from '@/types';
import useAxios from '@/util/axios';
import { cloneJSON, decodeJSON } from '@/util/JSON';

import defaults from './defaultData/brandingTools';

const brandingTools = ref(
  cloneJSON(defaults.value) as BrandingTools,
);

const loading = ref(true);

export default function useBrandingToolsApi() {
  const { api } = useAxios();

  const alignJSONs = (fromObj: GenericJson, toObj: GenericJson): GenericJson => {
    const fromObjKeys = Object.keys(fromObj);
    const toObjKeys = Object.keys(toObj);

    fromObjKeys.forEach((key: string) => {
      if (-1 === toObjKeys.indexOf(key)) {
        toObj[key] = fromObj[key];
      } else if ('string' === typeof fromObj[key] || fromObj[key] instanceof Array) {
        toObj[key] = fromObj[key] ? fromObj[key] : toObj[key];
      } else if ('object' === typeof fromObj[key]) {
        toObj[key] = alignJSONs(fromObj[key], toObj[key]);
      }
    });
    return toObj;
  };

  const load = async () => {
    loading.value = true;
    await api.get(API_ROUTE_BRANDING_TOOLS).then((response) => {
      if (response) {
        alignJSONs(decodeJSON(response.data), brandingTools.value);
      }
    }).finally(() => {
      loading.value = false;
    });
  };

  return {
    ...toRefs(brandingTools.value),
    loading,
    load,
  };
}
