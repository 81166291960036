import axios, { AxiosError } from 'axios';
import { AZURE_LOGIN_ROUTE } from '@/router/routes';
import { CODE_FORBIDDEN, CODE_UNAUTHORIZED } from '@/util/httpStatusCodes';
import { domain } from './domainUtils';
import { getCookie } from './cookies';

export default function useAxios(interceptErrors = false) {
  const api = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${getCookie('authorization_token')}`,
      env: domain,
    },
  });

  if (interceptErrors) {
    api.interceptors.response.use((response) => response, (error: AxiosError) => {
      if (!error.response) {
        throw error;
      }

      if (
        CODE_UNAUTHORIZED === error.response.status || CODE_FORBIDDEN === error.response.status
      ) {
        window.location.replace(AZURE_LOGIN_ROUTE);
        return;
      }

      throw error;
    });
  }

  const request = axios.CancelToken.source();

  return {
    api,
    request,
  };
}
