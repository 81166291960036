import { createRouter, createWebHistory, Router } from 'vue-router';
import { ROUTE_PATH_DISCLAIMER, ROUTE_PATH_TERMS_AND_CONDITIONS } from '@/router/routes';
import Routes from '@/router/appRoutes';

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Routes,
  scrollBehavior(to) {
    if (-1 < [ROUTE_PATH_DISCLAIMER, ROUTE_PATH_TERMS_AND_CONDITIONS].indexOf(to.path) && to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    return { top: 0 };
  },
});

export default router;
