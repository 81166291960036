import { RouteRecordRaw } from 'vue-router';
import {
  ROUTE_PATH_EXPLORE,
  ROUTE_NAME_EXPLORE,
  ROUTE_TITLE_EXPLORE,
  ROUTE_PATH_QUESTIONNAIRE,
  ROUTE_NAME_QUESTIONNAIRE,
  ROUTE_TITLE_QUESTIONNAIRE,
  ROUTE_PATH_MY_DOCS,
  ROUTE_NAME_MY_DOCS,
  ROUTE_TITLE_MY_DOCS,
  ROUTE_PATH_DISCLAIMER,
  ROUTE_NAME_DISCLAIMER,
  ROUTE_TITLE_DISCLAIMER,
  ROUTE_PATH_PRIVACY_POLICY,
  ROUTE_NAME_PRIVACY_POLICY,
  ROUTE_TITLE_PRIVACY_POLICY,
  ROUTE_PATH_TERMS_AND_CONDITIONS,
  ROUTE_NAME_TERMS_AND_CONDITIONS,
  ROUTE_TITLE_TERMS_AND_CONDITIONS,
  ROUTE_PATH_MIGRATED_DOCS,
  ROUTE_NAME_MIGRATED_DOCS,
  ROUTE_TITLE_MIGRATED_DOCS,
} from '@/router/routes';

const Routes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_PATH_EXPLORE,
    name: ROUTE_NAME_EXPLORE,
    component: () => import('@/views/Explore/Explore.vue'),
    meta: {
      requiresAuth: true,
      fullName: ROUTE_TITLE_EXPLORE,
    },
  },
  {
    path: ROUTE_PATH_MY_DOCS,
    name: ROUTE_NAME_MY_DOCS,
    component: () => import('@/views/MyDocs/MyDocs.vue'),
    meta: {
      requiresAuth: true,
      fullName: ROUTE_TITLE_MY_DOCS,
    },
  },
  {
    path: ROUTE_PATH_MIGRATED_DOCS,
    name: ROUTE_NAME_MIGRATED_DOCS,
    component: () => import('@/views/MigratedDocs/MigratedDocs.vue'),
    meta: {
      requiresAuth: true,
      fullName: ROUTE_TITLE_MIGRATED_DOCS,
    },
  },
  {
    path: ROUTE_PATH_QUESTIONNAIRE,
    name: ROUTE_NAME_QUESTIONNAIRE,
    component: () => import('@/views/Questionnaire/Questionnaire.vue'),
    meta: {
      requiresAuth: true,
      fullName: ROUTE_TITLE_QUESTIONNAIRE,
    },
  },
  {
    path: ROUTE_PATH_DISCLAIMER,
    name: ROUTE_NAME_DISCLAIMER,
    component: () => import('@/views/Disclaimer/Disclaimer.vue'),
    meta: {
      hasFooter: false,
      fullName: ROUTE_TITLE_DISCLAIMER,
    },
  },
  {
    path: ROUTE_PATH_PRIVACY_POLICY,
    name: ROUTE_NAME_PRIVACY_POLICY,
    component: () => import('@/views/PrivacyPolicy/PrivacyPolicy.vue'),
    meta: {
      hasFooter: false,
      fullName: ROUTE_TITLE_PRIVACY_POLICY,
    },
  },
  {
    path: ROUTE_PATH_TERMS_AND_CONDITIONS,
    name: ROUTE_NAME_TERMS_AND_CONDITIONS,
    component: () => import('@/views/TermsAndConditions/TermsAndConditions.vue'),
    meta: {
      hasFooter: false,
      fullName: ROUTE_TITLE_TERMS_AND_CONDITIONS,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: ROUTE_PATH_EXPLORE,
  },
];

export default Routes;
