import { Buffer } from 'buffer';
import { GenericJson } from '@/types';

export const cloneJSON = (json: Object) => JSON.parse(JSON.stringify(json));

export const decodeJSON = (json: GenericJson) => {
  const keys = Object.keys(json);

  const encodedJSON = {} as GenericJson;

  keys.forEach((key: string) => {
    encodedJSON[key] = JSON.parse(
      Buffer.from(
        json[key],
        'base64',
      ).toString('utf-8'),
    );
  });

  return encodedJSON;
};
